/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Button, Card, Col, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { Toaster, toast } from "react-hot-toast";
const Catagory = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [Showupdate, setshowupdate] = useState(false);
  const [showlodder, setshowloader] = useState(true);
  const [search, setserach] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [addcatagory, setCataegory] = useState("");
  const [data, setdata] = useState([]);
  const [updatedata, setupdated] = useState([]);
  const [updateId, setUpdateid] = useState("");

  // ----------------addcatagory-----------------------
  const submit = (e) => {
    e.preventDefault();
    if (addcatagory === "") {
      toast.error("please fill the field");
    } else {
      setshowloader(true);
      const fromdata = new FormData();
      fromdata.append("name", addcatagory);
      axiosInstance
        .post(`category`, fromdata)
        .then((response) => {
          setCataegory("");
          setBasicModal(false);
          toast.success("Add Cetagory Succcesfully");
          fetchData();
          setshowloader(false);
        })
        .catch((error) => {
          console.log(error, "erroe");
          setshowloader(false);
        });
    }
  };

  // ------------------------getallcatagory--------------------
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`category`);
      console.log(response?.data?.categories, "response?.data?.categories");
      setdata(response?.data?.categories);
      setfilteredData(response?.data?.categories);
      setshowloader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setshowloader(false);
    }
  };
  // -----------------fillter------------------------
  useEffect(() => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    setfilteredData(filtered);
  }, [search]);
  // ------------update------------
  const update = (data) => {
    setupdated(data);
    setCataegory(data.name);
    setUpdateid(data.id);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setshowloader(true);
    const fromdata = new FormData();
    fromdata.append("name", addcatagory);
    fromdata.append("_method", "PUT");
    try {
      const response = await axiosInstance.post(
        `category/${updateId}`,
        fromdata
      );
      if (response.status === 200) {
        toast.success("Updated Tool Succcesfully");
        fetchData();
        setupdated([]);
        setBasicModal(false);
        setshowloader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // --------------------------delete---------
  const Delete = async (id) => {
    setshowloader(true);
    try {
      const response = await axiosInstance.delete(`category/${id}`);
      if (response.status === 200) {
        toast.success("deleted Catagory Succcesfully");
        fetchData();
        setshowloader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <Fragment>
      {showlodder ? <div className="loader"></div> : null}
      <Toaster position="top-right" reverseOrder={false} />
      <PageTitle activeMenu="Category" motherMenu="Tools" />
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Categories</Card.Title>
        
            <div className="input-group search-area shadow">
              <input
                type="text"
                onChange={(e) => {
                  setserach(e.target.value);
                }}
                className="form-control "
                placeholder="Search Here"
              />
              <span className="input-group-text">
                  <i className="flaticon-381-search-2"></i>
              </span>
            </div>
            <button
              className="btn btn-custom"
              onClick={() => {
                setBasicModal(true);
                setshowupdate(false);
              }}
            >
              Add Category
            </button>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>ID</strong>
                  </th>
                  <th>
                    <strong>Category Name</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <strong>{data.id}</strong>
                      </td>
                      <td width={"80%"}>{data.name}</td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i
                              className="fas fa-pen"
                              onClick={() => {
                                setBasicModal(true);
                                setshowupdate(true);
                                update(data);
                              }}
                            ></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i
                              className="fa fa-trash"
                              onClick={(e) => Delete(data.id)}
                            ></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Modal className="fade" show={basicModal}>
              <Modal.Header>
                <Modal.Title>
                  {Showupdate ? "update Cetagory" : "Add Cetagory"}
                </Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setBasicModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <from onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3 col-md-12">
                    <h5 style={{ color: "black" }}>Category </h5>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Category"
                      defaultValue={updatedata.name}
                      onChange={(e) => setCataegory(e.target.value)}
                    />
                  </div>
                </from>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setBasicModal(false)}
                  variant="danger light"
                >
                  Close
                </Button>
                <Button
                  className="btn-custom"
                  onClick={Showupdate ? handleUpdate : submit}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
            {/* <Row className="justify-content-center">
              <Col xl={4} className=" col-xxl-6 ">
                <Nav>{pag("", true, "danger", true, false)}</Nav>
              </Col>
            </Row> */}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Catagory;
