/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { ColumnFilter } from "../../table/FilteringTable/ColumnFilter";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "../../table/FilteringTable/filtering.css";
import { Col, Card, Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GlobalFilter } from "../../table/FilteringTable/GlobalFilter";
import axiosInstance from "../../../../services/AxiosInstance";
import { Toaster, toast } from "react-hot-toast";
const Glossary = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [showlodder, setshowloader] = useState(true);
  const [Title, setTitle] = useState("");
  const [Newsurl, setNewsurl] = useState("");
  const [updatedata, setupatedata] = useState([]);
  const [updateid, setupdateid] = useState("");
  const [checkUpdate, setCheckupdate] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`glossary`);
      setData(response?.data?.glossaries);
      setshowloader(false);
    } catch (error) {
      setshowloader(false);
      toast.error(error);
    }
  };
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Heading",
      Footer: "Heading",
      accessor: "heading",
      Filter: ColumnFilter,
    },
    {
      Header: "Description",
      Footer: "Description",
      accessor: "description",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
      Cell: ({ value, row }) => {
        return value !== 1 ? (
          <div
            className="curser"
            onClick={() => {
              handleStatus(row.original.id);
            }}
          >
            <i className="fa fa-circle text-danger me-1 "></i>
            Deactive
          </div>
        ) : (
          <div className="curser" onClick={() => handleStatus(row.original.id)}>
            <i className="fa fa-circle text-success me-1  "></i>
            Active
          </div>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "id",
      id: "action",
      Cell: ({ value }) => {
        return (
          <div className="d-flex">
            <Link href="#" className="btn btn-primary shadow btn-xs sharp me-1">
              <i
                className="fas fa-pen"
                onClick={(e) => handleupdate(value)}
              ></i>
            </Link>
            <Link href="#" className="btn btn-danger shadow btn-xs sharp">
              <i
                className="fa fa-trash"
                onClick={(e) => handleDelete(value)}
              ></i>
            </Link>
          </div>
        );
      },
      Filter: ColumnFilter,
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const [data, setData] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  // ------------------------handlesumbit--------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    if (Title === "" || Newsurl === "") {
      toast.error("Please Fill All The Field");
    } else {
      try {
        setshowloader(true);
        const formdata = new FormData();
        formdata.append("heading", Title);
        formdata.append("description", Newsurl);
        axiosInstance
          .post(`glossary`, formdata)
          .then((response) => {
            setshowloader(false);
            setBasicModal(false);
            fetchData();
            toast.success(response.data.message);
          })
          .catch((errors) => {
            setshowloader(false);
            toast.error(errors.response.data.message);
          });
      } catch (error) {
        toast.error(error);
      }
    }
  };
  // -------------handleStatus-------------------
  const handleStatus = async (value) => {
    setshowloader(true);
    try {
      const response = await axiosInstance.get(`/glossary/status/${value}`);
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
        setshowloader(false);
      }
    } catch (error) {
      setshowloader(false);
      console.error("Error fetching data:", error);
    }
  };
  // ------------------------handleupdate-----------------------
  const handleupdate = (value) => {
    setshowloader(true);
    setCheckupdate(false);
    axiosInstance
      .get(`glossary/${value}`)
      .then((response) => {
        console.log(response?.data?.learn.description, "dsadasdasdasdsa");
        setupatedata(response?.data?.learn);
        setBasicModal(true);
        setupdateid(response?.data?.learn.id);
        setTitle(response?.data?.learn.heading);
        setNewsurl(response?.data?.learn.description);
        setshowloader(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const handleUpdatesumbit = (e) => {
    e.preventDefault();
    if (Title === "" || Newsurl === "") {
      toast.error("Please Fill All The Field");
    } else {
      setshowloader(true);
      const formdata = new FormData();
      formdata.append("heading", Title);
      formdata.append("_method", "PUT");
      formdata.append("description", Newsurl);

      try {
        // var config={
        //   method : 'post',
        //   url :`https://aitool.dev-hi.xyz/api/admin/ainews/${updateid}`,
        //   headers : {
        //     Accept : 'applictaion/json',
        //     Authorization :`Bearer ${token}`,
        //     data : formdata
        //   }
        // }
        axiosInstance
          .post(`glossary/${updateid}`, formdata)
          .then((response) => {
            setshowloader(false);
            setBasicModal(false);
            fetchData();
            toast.success(response.data.message);
          })
          .catch((error) => {
            setshowloader(false);
            toast.error(error);
          });
      } catch (error) {
        console.log(error, "eroorere");
        toast.error(error);
      }
    }
  };
  // ---------------------------handledlt----------------
  const handleDelete = async (value) => {
    setshowloader(true);
    try {
      const response = await axiosInstance.delete(`glossary/${value}`);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        fetchData();
        setshowloader(false);
      }
    } catch (error) {
      toast.error(error);
      setshowloader(false);
    }
  };
  return (
    <Fragment>
      {showlodder ? <div className="loader"></div> : null}
      <Toaster position="top-right" reverseOrder={false} />
      <PageTitle activeMenu="Prompt Engineering" motherMenu="Prompt Engineering" />
      {/* <FilteringTable/> */}
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Prompt Engineering</Card.Title>
            {/* <button className="Btn Btn-info">Add Tool</button> */}
            <button
              className="btn btn-custom"
              onClick={() => {
                setBasicModal(true);
                setCheckupdate(true);
                setupatedata([]);
              }}
            >
              Add Prompt Engineering
            </button>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              <table {...getTableProps()} className="table  display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <>
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        </>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              <>{cell.render("Cell")}</>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {""}
                </span>
                <span className="table-index">
                  Go to page :{" "}
                  <input
                    type="number"
                    className="ml-2"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                  />
                </span>
              </div>
              <div className="text-center">
                <div className="filter-pagination  mt-3">
                  <button
                    className=" previous-button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <button
                    className="next-button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <button
                    className=" next-button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Modal className="fade bd-example-modal-lg" show={basicModal}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            {checkUpdate ? "Add Prompt Engineering" : "Update Prompt Engineering"}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-12">
                <h5 style={{ color: "black" }}>Heading</h5>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Heading"
                  defaultValue={updatedata.heading}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="form-group mb-3 col-md-12">
                <h5 style={{ color: "black" }}>Description</h5>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Description"
                  defaultValue={updatedata.description}
                  onChange={(e) => setNewsurl(e.target.value)}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setBasicModal(false)} variant="danger light">
            Close
          </Button>
          <Button
            className="btn-custom"
            onClick={checkUpdate ? handleSubmit : handleUpdatesumbit}
          >
            {checkUpdate ? "Submit" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Glossary;
