/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { ColumnFilter } from "../../table/FilteringTable/ColumnFilter";
import {  Card, Col } from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "../../table/FilteringTable/filtering.css";
import { useState } from "react";
import { GlobalFilter } from "../../table/FilteringTable/GlobalFilter";
import axiosInstance from "../../../../services/AxiosInstance";
import { Toaster, toast } from "react-hot-toast";
const AppProfile = () => {
  const [showlodder, setshowloader] = useState(true);
    

  // const [basicModal, setBasicModal] = useState(false);
  // const [updatedata, setupatedata] = useState([]);
  // const [updateid, setupdateid] = useState("");
  // const [name, setname] = useState("");
  // const [Email, setEmail] = useState("");
  // const [toolname, settoolname] = useState("");
  // const [toolurl, settoolurl] = useState("");
  // const [Category, setcategory] = useState([]);
  // const [Pricing, setpricing] = useState([]);
  // const [Cataegory_id, setCataegory_id] = useState("");
  // const [pricing_id, setPricing_id] = useState("");
  // const [Discription, setdiscription] = useState("");
  // const [otherdiscription, setOtherdiscription] = useState("");
  // const [img, setimg] = useState("");
  // const [Showimg, setshowimg] = useState();
  // const [checkUpdate, setCheckupdate] = useState(true);
  // const ref = useRef();
  // const submitimg = (e) => {
  //   ref.current.click();
  // };
  // const handleImage = (e) => {
  //   setimg(e.target.files[0]);
  //   if (e.target.files[0]) {
  //     const imageUrl = URL.createObjectURL(e.target.files[0]);
  //     setshowimg(imageUrl);
  //   }
  // };
  useEffect(() => {
    fetchData();
    // fetchpricing();
    // fetchcatagory();
  }, []);
  const fetchData = async () => {
    setshowloader(true)
    try {
      const response = await axiosInstance.get(`review`);
      console.log(response,'deadasadsadas')
      if(response.status === 200){
        setData(response.data.data);
        setshowloader(false)
      }
      // setshowloader(false);
    } catch (error) {
      // setshowloader(false);
      toast.error(error);
    }
  };
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: "attachment",
      Cell: ({ value, row }) => {
        return (
          <div>
            <img src={value} alt="images" width={'50px'} />
          </div>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      Filter: ColumnFilter,
    },
    {
      Header: "Review",
      Footer: "Review",
      accessor: "review",
      Filter: ColumnFilter,
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    {
      Header: "Star",
      Footer: "Star",
      accessor: "stars",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "is_status",
      Cell: ({ value, row }) => {
        return value !== 1 ? (
          <div
            className="curser"
            onClick={() => {
              handleStatus(row.original.id);
            }}
          >
            <i className="fa fa-circle text-danger me-1 "></i>
            Deactive
          </div>
        ) : (
          <div className="curser" onClick={() => handleStatus(row.original.id)}>
            <i className="fa fa-circle text-success me-1  "></i>
            Active
          </div>
        );
      },
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Action",
    //   Footer: "Action",
    //   accessor: "id",
    //   id: "action",
    //   Cell: ({ value }) => {
    //     return (
    //       <div className="d-flex">
    //         <Link href="#" className="btn btn-primary shadow btn-xs sharp me-1">
    //           <i
    //             className="fas fa-pen"
    //             onClick={(e) => handleupdate(value)}
    //           ></i>
    //         </Link>
    //         <Link href="#" className="btn btn-danger shadow btn-xs sharp">
    //           <i
    //             className="fa fa-trash"
    //             onClick={(e) => handleDelete(value)}
    //           ></i>
    //         </Link>
    //       </div>
    //     );
    //   },
    //   Filter: ColumnFilter,
    // },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const [data, setData] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  // --------------fatchpricingandcataogory-------------------
  // const fetchpricing = async () => {
  //   try {
  //     const response = await axiosInstance.get(`category`);
  //     setpricing(response?.data?.pricings);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // const fetchcatagory = async () => {
  //   try {
  //     const response = await axiosInstance.get(`category`);
  //     setcategory(response?.data?.categories);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // ------------------------handlesumbit--------------------
 
  // -------------handleStatus-------------------
  const handleStatus = async (value) => {
    setshowloader(true);
    try {
      const response = await axiosInstance.post(`/review/status-change/${value}`);
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
        setshowloader(false);
      }
    } catch (error) {
      setshowloader(false);
      console.error("Error fetching data:", error);
    }
  };
  // ------------------------handleupdate-----------------------
 
  // ---------------------------handledlt----------------
  // const handleDelete = async (value) => {
  //   setshowloader(true);
  //   try {
  //     const response = await axiosInstance.delete(`futuretool/${value}`);
  //     if (response.status === 200) {
  //       toast.success(response?.data?.message);
  //       fetchData();
  //       setshowloader(false);
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //     setshowloader(false);
  //   }
  // };
  return (
    <Fragment>
      {showlodder ? <div className="loader"></div> : null}
      <Toaster position="top-right" reverseOrder={false} />
      <PageTitle activeMenu="Reviews" motherMenu="Reviews" />
      {/* <FilteringTable/> */}
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Reviews</Card.Title>
            {/* <button className="Btn Btn-info">Add Tool</button> */}
           
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              <table {...getTableProps()} className="table  display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <>
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        </>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              <>{cell.render("Cell")}</>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {""}
                </span>
                <span className="table-index">
                  Go to page :{" "}
                  <input
                    type="number"
                    className="ml-2"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                  />
                </span>
              </div>
              <div className="text-center">
                <div className="filter-pagination  mt-3">
                  <button
                    className=" previous-button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <button
                    className="next-button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <button
                    className=" next-button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    
    </Fragment>
  );
};

export default AppProfile;
