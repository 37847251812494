/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { ColumnFilter } from "../../table/FilteringTable/ColumnFilter";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "../../table/FilteringTable/filtering.css";
import { Col, Card } from "react-bootstrap";
import { useState } from "react";
import { GlobalFilter } from "../../table/FilteringTable/GlobalFilter";
import axiosInstance from "../../../../services/AxiosInstance";
// import { Toaster, toast } from "react-hot-toast";
const Ainews = () => {
  const [showlodder, setshowloader] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`contact`);
      console.log(response, "contact");
      if(response.status === 200){
        setData(response?.data?.data);
        setshowloader(false);
      }
   
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      Filter: ColumnFilter,
    },
    {
      Header: "Email",
      Footer: "email",
      accessor: "email",
      Filter: ColumnFilter,
    },
    
    {
      Header: "phone",
      Footer: "phone",
      accessor: "phone",
      Filter: ColumnFilter,
    },
    {
      Header: "subject",
      Footer: "subject",
      accessor: "subject",
      Filter: ColumnFilter,
    },
    {
      Header: "message",
      Footer: "message",
      accessor: "message",
      Filter: ColumnFilter,
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const [data, setData] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  
  const { globalFilter, pageIndex } = state;
 

 
 
  return (
    <Fragment>
      {showlodder ? <div className="loader"></div> : null}
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
      <PageTitle activeMenu="Contact" motherMenu="Contact" />
      {/* <FilteringTable/> */}
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Contact</Card.Title>
            {/* <button className="Btn Btn-info">Add Tool</button> */}
           
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              <table {...getTableProps()} className="table  display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <>
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        </>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              <>{cell.render("Cell")}</>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {""}
                </span>
                <span className="table-index">
                  Go to page :{" "}
                  <input
                    type="number"
                    className="ml-2"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                  />
                </span>
              </div>
              <div className="text-center">
                <div className="filter-pagination  mt-3">
                  <button
                    className=" previous-button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <button
                    className="next-button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <button
                    className=" next-button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
     
    </Fragment>
  );
};

export default Ainews;
