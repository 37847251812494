import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import axiosInstance from "../../services/AxiosInstance";
//import { Link } from "react-router-dom";
// image
//import logo from "../../images/logo-full.png";
const ForgotPassword = ({ history }) => {
  const [email, setemai] = useState("");
  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("please Fill valid email");
    } else {
      try {
        const fromdata = new FormData();
        fromdata.append("email", email);
        axiosInstance
          .post(`forgot-password`, fromdata)
          .then((res) => {
            console.log(res,'responseforgert')
            if (res.status === 200) {
              toast.success(res.data.message);
              setemai("");
              history.push('/changepass')
            }
          
            // console.log(res, "forget");
          })
          .catch((err) => {
            
              toast.error('email is Invalid');
             
          });
      } catch (error) {
        console.log(error,'error');
      }
    }
  };
  return (
    <div className="authincation h-100 p-meddle">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      {/* <Link to="/dashboard">
							<img src={logo} alt="" />
						</Link> */}
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label className="">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          value={email}
                          className="form-control"
                          defaultValue=""
                          onChange={(e) => setemai(e.target.value)}
                        />
                      </div>
                      <div className="text-center mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
