/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo, useRef } from "react";
import PageTitle from "../../../layouts/PageTitle";
import profile from "../../../../images/profile/profile.png";
import { ColumnFilter } from "../../table/FilteringTable/ColumnFilter";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "../../table/FilteringTable/filtering.css";
import { Col, Card, Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GlobalFilter } from "../../table/FilteringTable/GlobalFilter";
import axiosInstance from "../../../../services/AxiosInstance";
import { Toaster, toast } from "react-hot-toast";
const Learn = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [showlodder, setshowloader] = useState(true);
  const [Title, setTitle] = useState("");
  const [Date, setDate] = useState("");
  const [Newsurl, setNewsurl] = useState("");
  const [description, setdescription] = useState("");
  const [img, setimg] = useState("");
  const [Showimg, setshowimg] = useState();
  const [updatedata, setupatedata] = useState([]);
  const [updateid, setupdateid] = useState("");
  const [checkUpdate, setCheckupdate] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`learn`);
      setData(response?.data?.learns);
      setshowloader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    {
      Header: "Url",
      Footer: "Url",
      accessor: "video_url",
      Filter: ColumnFilter,
    },
    {
      Header: "Date",
      Footer: "Date",
      accessor: "date",
      Filter: ColumnFilter,
    },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "id",
      id: "action",
      Cell: ({ value }) => {
        return (
          <div className="d-flex">
            <Link href="#" className="btn btn-primary shadow btn-xs sharp me-1">
              <i
                className="fas fa-pen"
                onClick={(e) => handleupdate(value)}
              ></i>
            </Link>
            <Link href="#" className="btn btn-danger shadow btn-xs sharp">
              <i
                className="fa fa-trash"
                onClick={(e) => handleDelete(value)}
              ></i>
            </Link>
          </div>
        );
      },
      Filter: ColumnFilter,
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const [data, setData] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const ref = useRef();
  const submitimg = (e) => {
    ref.current.click();
  };
  const handleImage = (e) => {
    setimg(e.target.files[0]);
    if (e.target.files[0]) {
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setshowimg(imageUrl);
    }
  };
  const { globalFilter, pageIndex } = state;
  // ------------------------handlesumbit--------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    if (Title === "" || Date === "" || Newsurl === "" || img === "" || description === "") {
      toast.error("Please Fill All The Field");
    } else {
      try {
        setshowloader(true);
        const formdata = new FormData();
        formdata.append("title", Title);
        formdata.append("date", Date);
        formdata.append("video_url", Newsurl);
        formdata.append("image", img);
        formdata.append("description", description);
        axiosInstance
          .post(`learn`, formdata)
          .then((response) => {
            console.log(response, "dasdasdasdasdsadasd");
            setshowloader(false);
            setBasicModal(false);
            fetchData();
            toast.success(response.data.message);
          })
          .catch((errors) => {
            toast.error(errors.response.data.message);
            setshowloader(false);
          });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };
  // ------------------------handleupdate-----------------------
  const handleupdate = (value) => {
    try {
      setshowloader(true);
      setCheckupdate(false);
      axiosInstance
        .get(`learn/${value}`)
        .then((response) => {
          console.log(response, "sadasdfvjbbdjasbd");
          setupatedata(response?.data?.learn);
          setBasicModal(true);
          setshowimg(`https://aitool.dev-hi.xyz${response?.data?.learn.image}`);
          setupdateid(response?.data?.learn.id);
          setimg(response?.data?.learn.image);
          setTitle(response?.data?.learn.title);
          setNewsurl(response?.data?.learn.video_url);
          setDate(response?.data?.learn.date);
          setdescription(response?.data?.description)
          setshowloader(false);
        })
        .catch((errors) => {
          toast.error(errors.response.data.message);
          console.log(errors);

          setshowloader(false);
        });
    } catch (error) {
      toast.error(error.response.data.message);
      setshowloader(false);
    }
  };
  const handleUpdatesumbit = (e) => {
    e.preventDefault();
    if (Title === "" || Date === "" || Newsurl === "") {
      toast.error("Please Fill All The Field");
    } else {
      setshowloader(true);
      const formdata = new FormData();
      formdata.append("title", Title);
      formdata.append("date", Date);
      formdata.append("_method", "PUT");
      formdata.append("video_url", Newsurl);
      formdata.append("description", description);

      if (typeof img === "string") {
      } else {
        formdata.append("image", img);
      }
      try {
        // var config={
        //   method : 'post',
        //   url :`https://aitool.dev-hi.xyz/api/admin/ainews/${updateid}`,
        //   headers : {
        //     Accept : 'applictaion/json',
        //     Authorization :`Bearer ${token}`,
        //     data : formdata
        //   }
        // }
        axiosInstance
          .post(`learn/${updateid}`, formdata)
          .then((response) => {
            setshowloader(false);
            setBasicModal(false);
            fetchData();
            toast.success(response.data.message);
          })
          .catch((error) => {
            setshowloader(false);
            toast.error(error);
          });
      } catch (error) {
        console.log(error, "eroorere");
        toast.error(error);
      }
    }
  };
  // ---------------------------handledlt----------------
  const handleDelete = async (value) => {
    setshowloader(true);
    try {
      const response = await axiosInstance.delete(`learn/${value}`);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        fetchData();
        setshowloader(false);
      }
    } catch (error) {
      toast.error(error);
      setshowloader(false);
    }
  };
  return (
    <Fragment>
      {showlodder ? <div className="loader"></div> : null}
      <Toaster position="top-right" reverseOrder={false} />
      <PageTitle activeMenu="Learn" motherMenu="Learn" />
      {/* <FilteringTable/> */}
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Learn</Card.Title>
            {/* <button className="Btn Btn-info">Add Tool</button> */}
            <button
              className="btn btn-custom"
              onClick={() => {
                setBasicModal(true);
                setimg("");
                setCheckupdate(true);
                setupatedata([]);
              }}
            >
              Add Learn
            </button>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              <table {...getTableProps()} className="table  display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <>
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        </>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              <>{cell.render("Cell")}</>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-between">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {""}
                </span>
                <span className="table-index">
                  Go to page :{" "}
                  <input
                    type="number"
                    className="ml-2"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                  />
                </span>
              </div>
              <div className="text-center">
                <div className="filter-pagination  mt-3">
                  <button
                    className=" previous-button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <button
                    className="next-button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <button
                    className=" next-button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Modal className="fade bd-example-modal-lg" size="lg" show={basicModal}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            {checkUpdate ? "Add Learn" : "Update Learn"}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <h5 style={{ color: "black" }}>Title</h5>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ai news title"
                  defaultValue={updatedata.title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <h5 style={{ color: "black" }}>Date </h5>
                <input
                  type="date"
                  className="form-control"
                  defaultValue={updatedata.date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-12">
                <h5 style={{ color: "black" }}>Video URL</h5>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Url"
                  defaultValue={updatedata.video_url}
                  onChange={(e) => setNewsurl(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-12">
                <h5 style={{ color: "black" }}>Description</h5>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Description"
                  defaultValue={updatedata.description}
                  onChange={(e) => setdescription(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-12">
                <h5 style={{ color: "black" }}>Image</h5>
                <img
                  src={img ? Showimg : profile}
                  alt="demo"
                  width={"50px"}
                  onClick={submitimg}
                />
                <span className="mx-3">Image</span>
                <input
                  type="file"
                  ref={ref}
                  onChange={handleImage}
                  className="d-none"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setBasicModal(false)} variant="danger light">
            Close
          </Button>
          <Button
            className="btn-custom"
            onClick={checkUpdate ? handleSubmit : handleUpdatesumbit}
          >
            {checkUpdate ? "Submit" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Learn;
