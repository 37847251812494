import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import axiosInstance from "../../services/AxiosInstance";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ChangePassword = () => {
  const history = useHistory()
  const [password, setPassword] = useState("");
  const [email, setemail] = useState("");
  const [OTP, setOTP] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    if (password === "" || email === "" || OTP === '') {
      toast.error("please Fill the Feild");
    }  else {
      try {
        const fromdata = new FormData();
        fromdata.append("password", password);
        fromdata.append("email", email);
        fromdata.append("otp", OTP);
        axiosInstance
          .post(`reset-password`, fromdata)
          .then((res) => {
            console.log(res)

           if (res.status === 200) {
              toast.success(res.data.message);
              setemail('')
              setPassword('')
              history.push('/login')
            }
          })
          .catch((errors) => {
            toast.error(errors.message)
           
          });
      } catch (error) {
        console.log(error,'rerersadasdsad')
      }
    }
  };
  return (
    <>
      <div className="authincation h-100 p-meddle">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="container h-100">
          {" "}
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        {/* <Link to="/dashboard">
							<img src={logo} alt="" />
						</Link> */}
                      </div>
                      <h4 className="text-center mb-4 ">Reset Password</h4>
                      <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                          <label className="">
                            <strong>OTP</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={OTP}
                            defaultValue=""
                            onChange={(e) => setOTP(e.target.value)}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label className="">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            defaultValue=""
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="">
                            <strong>New Password</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            defaultValue=""
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                       
                        <div className="text-center mt-3">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
