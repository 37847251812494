import React, { useState } from "react";
import { Fragment } from "react";
import PageTitle from "../../../layouts/PageTitle";
import {
  Button,
  Card,
  Col,
  Modal,
  Nav,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
const Pricing = () => {
  const [basicModal, setBasicModal] = useState(false);
  const active = 1;
  let items = [];

  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const pag = (size, gutter, variant, bg, circle) => (
    <Pagination
      size={size}
      className={`mt-4  ${gutter ? "pagination-gutter" : ""} ${
        variant && `pagination-${variant}`
      } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i className="la la-angle-left" />
        </Link>
      </li>
      {items}
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i className="la la-angle-right" />
        </Link>
      </li>
    </Pagination>
  );
  return (
   <Fragment>
     <PageTitle activeMenu="Pricing" motherMenu="Tools" />
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Pricing</Card.Title>
            {/* <button className="Btn Btn-info">Add Tool</button> */}
            <button className='btn btn-danger' onClick={() => setBasicModal(true)}>
              Add Pricing
            </button>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>ID</strong>
                  </th>
                  <th>
                    <strong>Pricing</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>542</strong>
                  </td>
                  <td width={'80%'}>Exaplme</td>
                  <td>
                    <div className="d-flex">
                      <Link
                        href="#"
                        className="btn btn-primary shadow btn-xs sharp me-1"
                      >
                        <i
                          className="fas fa-pen"
                          onClick={() => setBasicModal(true)}
                        ></i>
                      </Link>
                      <Link
                        href="#"
                        className="btn btn-danger shadow btn-xs sharp"
                      >
                        <i className="fa fa-trash"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>542</strong>
                  </td>
                  <td>Exaplme</td>
                  <td>
                    <div className="d-flex">
                      <Link
                        href="#"
                        className="btn btn-primary shadow btn-xs sharp me-1"
                      >
                        <i
                          className="fas fa-pen"
                          onClick={() => setBasicModal(true)}
                        ></i>
                      </Link>
                      <Link
                        href="#"
                        className="btn btn-danger shadow btn-xs sharp"
                      >
                        <i className="fa fa-trash"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>542</strong>
                  </td>
                  <td>Exaplme</td>
                  <td>
                    <div className="d-flex">
                      <Link
                        href="#"
                        className="btn btn-primary shadow btn-xs sharp me-1"
                      >
                        <i
                          className="fas fa-pen"
                          onClick={() => setBasicModal(true)}
                        ></i>
                      </Link>
                      <Link
                        href="#"
                        className="btn btn-danger shadow btn-xs sharp"
                      >
                        <i className="fa fa-trash"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Modal className="fade" show={basicModal}>
              <Modal.Header>
                <Modal.Title>Modal title</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setBasicModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <from onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3 col-md-12">
                    <h5 style={{ color: "black" }}>Pricing </h5>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Pricing"
                    />
                  </div>
                </from>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setBasicModal(false)}
                  variant="danger light"
                >
                  Close
                </Button>
                <Button variant="danger">Submit</Button>
              </Modal.Footer>
            </Modal>
            <Row className="justify-content-center">
              <Col xl={4} className=" col-xxl-6 ">
                <Nav>{pag("", true, "danger", true, false)}</Nav>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
   </Fragment>
  )
}

export default Pricing