import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: `https://misshaileyapi.dev-sh.xyz/api/`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
