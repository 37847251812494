/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo,  } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import MOCK_DATA from "./MOCK_DATA_2.json";
import { GlobalFilter } from "./GlobalFilter";
//import './table.css';
import "./filtering.css";
import { Button } from "react-bootstrap";
import { Link } from "@material-ui/core";
import { ColumnFilter } from "./ColumnFilter";

export const FilteringTable = () => {
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "link",
      Footer: "link",
      accessor: "link",
      Filter: ColumnFilter,
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Date of  Birth",
    //   Footer: "Date of  Birth",
    //   accessor: "date_of_birth",
    //   Cell: ({ value }) => {
    //     return format(new Date(value), "dd/mm/yyyy");
    //   },
    //   Filter: ColumnFilter,
    // },
    {
      Header: "Action",
      Footer: "Action",
      accessor: "id",
      id: "action",
      Cell: ({ value }) => {
        return (
          <div className="d-flex">
            <Link href="#" className="btn btn-primary shadow btn-xs sharp me-1">
              <i
                className="fas fa-pen"
                onClick={(e) => handleupdate(value)}
              ></i>
            </Link>
            <Link href="#" className="btn btn-danger shadow btn-xs sharp">
              <i
                className="fa fa-trash"
                onClick={(e) => handleDelete(value)}
              ></i>
            </Link>
          </div>
        );
      },
      Filter: ColumnFilter,
    },
  ];
  const handleupdate = (value) => {
    alert(value);
  };
  const handleDelete = (value) => {
    alert(value);
  };
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => MOCK_DATA, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  
  return (
    <>
      <div className="card">
        <div className="card-header ">
          <Button>Add Ai News</Button>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table  display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <>
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      </>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            <>{cell.render("Cell")}</>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FilteringTable;
