/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
	  componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	  }
	  componentWillUnmount() {
	  }
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	// var handleheartBlast = document.querySelector('.heart');
  //       function heartBlast() {
  //           return handleheartBlast.classList.toggle("heart-blast");
  //       }
  //       handleheartBlast.addEventListener('click', heartBlast);
	
  }
  , []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    // email = ["email-compose", "email-inbox", "email-read"],
    // shop = [
    //   "ecom-product-grid",
    //   "ecom-product-list",
    //   "ecom-product-list",
    //   "ecom-product-order",
    //   "ecom-checkout",
    //   "ecom-invoice",
    //   "ecom-customers",
    //   "ecom-product-detail",
    // ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ];
    // bootstrap = [
    //   "ui-accordion",
    //   "ui-badge",
    //   "ui-alert",
    //   "ui-button",
    //   "ui-modal",
    //   "ui-button-group",
    //   "ui-list-group",
    //   "ui-media-object",
    //   "ui-card",
    //   "ui-carousel",
    //   "ui-dropdown",
    //   "ui-popover",
    //   "ui-progressbar",
    //   "ui-tab",
    //   "ui-typography",
    //   "ui-pagination",
    //   "ui-grid",
    // ],
    // plugins = [
    //   "uc-select2",
    //   "uc-nestable",
    //   "uc-sweetalert",
    //   "uc-toastr",
    //   "uc-noui-slider",
    //   "map-jqvmap",
    //   "uc-lightgallery",
    // ]
    
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {/* <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text ">Dashboard</span>
            </Link>
            <ul >
              <li><Link className={`${path === "" ? "mm-active" : "dashboard"}`} to="/dashboard"> Dashboard</Link></li>
            </ul>
          </li> */}
          <li className={`${app.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-050-info"></i>
              <span className="nav-text">Reviews</span>
            </Link>
            <ul >
            
              {/* <li className={`${email.includes(path) ? "mm-active" : ""}`}><Link  to="#" > Tools</Link>
                <ul  className={`${email.includes(path) ? "mm-show" : ""}`}>
                  <li><Link className={`${ path === "email-compose" ? "mm-active" : ""}`} to="/email-compose">Compose</Link></li>
                  <li><Link className={`${path === "email-inbox" ? "mm-active" : ""}`} to="/email-inbox">Inbox</Link></li>
                  <li><Link className={`${path === "email-read" ? "mm-active" : ""}`} to="/email-read">Read</Link></li>
                </ul>
              </li> */}
              <li><Link className={`${path === "reviews" ? "mm-active" : ""}`} to="/reviews">Reviews</Link></li>
              {/* <li><Link className={`${path === "Catagory" ? "mm-active" : ""}`} to="/Category">Category</Link></li> */}
              {/* <li><Link className={`${path === "pricing" ? "mm-active" : ""}`} to="/pricing">Pricing</Link></li> */}
              {/* <li><Link className={`${path === "app-calender" ? "mm-active" : ""}`}to="/app-calender">Calendar</Link></li>
              <li className={`${shop.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Shop</Link>
                <ul  className={`${shop.includes(path) ? "mm-show" : ""}`}>
                  <li><Link className={`${ path === "ecom-product-grid" ? "mm-active" : ""}`} to="/ecom-product-grid">Product Grid</Link></li>
                  <li><Link className={`${ path === "ecom-product-list" ? "mm-active" : ""}`} to="/ecom-product-list">Product List</Link></li>
                  <li><Link className={`${ path === "ecom-product-detail" ? "mm-active" : "" }`} to="/ecom-product-detail">Product Details</Link></li>
                  <li><Link className={`${ path === "ecom-product-order" ? "mm-active" : "" }`} to="/ecom-product-order">Order</Link></li>
                  <li><Link className={`${ path === "ecom-checkout" ? "mm-active" : ""}`} to="/ecom-checkout">Checkout</Link></li>
                  <li><Link className={`${ path === "ecom-invoice" ? "mm-active" : "" }`} to="/ecom-invoice">Invoice</Link></li>
                  <li><Link className={`${ path === "ecom-customers" ? "mm-active" : "" }`} to="/ecom-customers">Customers</Link></li>
                </ul>
              </li> */}
            </ul>
          </li>
          <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Contact</span>
            </Link>
            <ul >
              <li>
                <Link
                  className={`${path === "contact" ? "mm-active" : ""}`}
                  to="/contact"
                >
					      Contact
                </Link>
              </li>
            </ul>
          </li>
          {/* <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-086-star"></i>
              <span className="nav-text">Learn</span>
            </Link>
            <ul >
              <li>
                <Link
                  className={`${path === "Learn" ? "mm-active" : ""}`}
                  to="/Learn"
                >
                  Learn
                </Link>
              </li>
            </ul>
          </li>
			<li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="flaticon-045-heart"></i><span className="nav-text">Prompt </span>
				</Link>
				<ul >
				  <li><Link className={`${path === "PromptEngineering" ? "mm-active" : ""}`} to="/PromptEngineering">Prompt </Link></li>				 
				</ul>
			</li> */}
		
        </MM>
		{/* <div className="copyright">
			<p><strong>Ai Tool Kit Admin</strong> © 2021 All Rights Reserved</p>
			<p className="fs-12">Made with <span className="heart"></span> by HNH TECH SOLUTIONS</p>
		</div> */}
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
